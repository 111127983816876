import React, { Component } from "react";

export class SubForm extends Component {
  constructor() {
    super();
    this.state = {
      message: "Subsribe to our mailing list for updates. ✨",
      display: "",
    };
  }
  handleSubmit = async (e) => {
    e.preventDefault();
    this.setState({
      message: "Thank you for subscribing! 🥳",
      display: "none",
    });
  };

  render() {
    return (
      <div className="section">
        <div className="container">
          <div className="section-head">
            <h2 className="">Newsletter</h2>
          </div>
          <h3 className="text-center">{this.state.message}</h3>

          <div
            className="contact row justify-content-center text-center"
            id="Newsletter"
          >
            <div
              className="col-lg-6"
              style={{ display: `${this.state.display}` }}
            >
              <form
                onSubmit={this.handleSubmit}
                method="POST"
                data-netlify="true"
                netlifyHoneypot="bot-field"
                name="email subscription"
              >
                <div class="form-group">
                  <input
                    type="email"
                    required
                    name="email"
                    className="shadow-sm rounded"
                    aria-describedby="emailHelp"
                    placeholder="Your Email Address"
                    style={{ height: "50px" }}
                  />
                  <p id="emailHelp" className="pt-3 lead text-muted">
                    We'll never share your email with anyone else, No spam.{" "}
                    <span role="img" aria-label="man gesturing no">
                      🙅‍♂️
                    </span>
                  </p>
                </div>
                <button type="submit" className="shadow">
                  <span>Subscribe</span>{" "}
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default SubForm;
